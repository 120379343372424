import { template as template_3d82c9f685904a48a00c067a754ec277 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_3d82c9f685904a48a00c067a754ec277(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
