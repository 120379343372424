import { template as template_47640901d1d94ac29b2f8b3f52b6dd9a } from "@ember/template-compiler";
const WelcomeHeader = template_47640901d1d94ac29b2f8b3f52b6dd9a(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
