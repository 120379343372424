import { template as template_da8991eee6c5482483fd0c04401a20e0 } from "@ember/template-compiler";
const FKLabel = template_da8991eee6c5482483fd0c04401a20e0(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
